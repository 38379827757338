var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","height":"425","width":"550"}},[_c('rect',{staticClass:"background",attrs:{"x":"0","y":"0","height":"425","width":"550"}}),_c('text',{staticClass:"text",attrs:{"transform":"translate(200, 40) rotate(30)"}},[_vm._v("Window")]),_c('line',{staticClass:"drawLine",attrs:{"x1":"290","y1":"30","x2":"290","y2":"395"}}),_c('text',{staticClass:"text",attrs:{"x":"390","y":"20"}},[_vm._v("Windows")]),_c('text',{staticClass:"text",attrs:{"x":"100","y":"405"}},[_vm._v("Art Gallery")]),_c('text',{staticClass:"text",attrs:{"transform":"translate(500, 300) rotate(-45)"}},[_vm._v("Stairs")]),_vm._l((_vm.carrels),function(ref){
var carrel = ref.carrel;
var x = ref.x;
var y = ref.y;
var height = ref.height;
var width = ref.width;
var rotate = ref.rotate;
return [_c('rect',{key:'rect-' + carrel,staticClass:"carrel",class:carrel === _vm.selectedCarrel ? 'selected' : _vm.carrelStatus[carrel],attrs:{"x":rotate ? 0 : x,"y":rotate ? 0 : y,"height":height,"width":width,"transform":rotate ? 'translate(' + x + ', ' + y + ') rotate(' + rotate + ')' : ''},on:{"click":function($event){return _vm.$emit('click', carrel)}}}),_c('text',{key:'text-' + carrel,staticClass:"text",class:carrel === _vm.selectedCarrel ? 'selected' : _vm.carrelStatus[carrel],attrs:{"x":rotate ? x + 5 + (width / 2) : x + (width / 2),"y":rotate ? y - 3 : y + (height / 2) + 2,"text-anchor":"middle","dominant-baseline":"middle"},on:{"click":function($event){return _vm.$emit('click', carrel)}}},[_vm._v(_vm._s(carrel))])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }