<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="425" width="550">
    <rect class="background" x="0" y="0" height="425" width="550"></rect>
    <text class="text" transform="translate(200, 40) rotate(30)">Window</text>
    <line class="drawLine" x1="290" y1="30" x2="290" y2="395"></line>
    <text class="text" x="390" y="20">Windows</text>
    <text class="text" x="100" y="405">Art Gallery</text>
    <text class="text" transform="translate(500, 300) rotate(-45)">Stairs</text>

    <template v-for="({ carrel, x, y, height, width, rotate }) of carrels">
      <rect
        :key="'rect-' + carrel"
        class="carrel"
        :class="carrel === selectedCarrel ? 'selected' : carrelStatus[carrel]"
        :x="rotate ? 0 : x"
        :y="rotate ? 0 : y"
        :height="height"
        :width="width"
        :transform="rotate ? 'translate(' + x + ', ' + y + ') rotate(' + rotate + ')' : ''"
        @click="$emit('click', carrel)"
        ></rect>
      <text
        :key="'text-' + carrel"
        class="text"
        :class="carrel === selectedCarrel ? 'selected' : carrelStatus[carrel]"
        :x="rotate ? x + 5 + (width / 2) : x + (width / 2)"
        :y="rotate ? y - 3 : y + (height / 2) + 2"
        text-anchor="middle"
        dominant-baseline="middle"
        @click="$emit('click', carrel)"
        >{{ carrel }}</text>
    </template>
  </svg>
</template>
<style>
  .background {
    fill: white;
  }
  .theme--dark.v-card .background {
    fill: rgb(50, 50, 50)
  }
  .drawLine {
    stroke: rgb(0,0,0)
  }
  .theme--dark.v-card .drawLine {
    stroke: rgb(255, 255, 255)
  }
  .carrel {
    fill: white;
    stroke: rgb(175, 175, 175);
    cursor: pointer;
  }
  .theme--dark.v-card .carrel {
    fill: rgb(50, 50, 50);
    stroke: rgb(175, 175, 175)
  }
  .carrel.selected, .theme--dark.v-card .carrel.selected {
    fill: #4CAF50;
  }
  .carrel.partial, .theme--dark.v-card .carrel.partial {
    fill: #FFA000;
  }
  .carrel.full, .theme--dark.v-card .carrel.full {
    fill: #FF5252;
    cursor: default;
  }
  .text, .theme--dark.v-card .text.selected {
    fill: rgb(0, 0, 0);
    cursor: pointer;
  }
  .theme--dark.v-card .text {
    fill: rgb(175, 175, 175)
  }
  .theme--dark.v-card .text.partial {
    fill: rgb(50, 50, 50)
  }
  .theme--dark.v-card .text.full {
    fill: rgb(50, 50, 50);
    cursor: default;
  }
</style>
<script>
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    selectedCarrel: {
      type: String,
      default: ''
    },
    takenCarrels: {
      type: Array,
      default: () => {
        return []
      }
    },
    // This is a hashed object with each carrel as key; the value will be the status of the carrel: selected, empty, partial, or full
    carrelStatus: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { root }) {
    const left = ref([
      '1', '2', '3', '4',
      'space',
      '8', '7', '6', '5', 'newline',
      '9', '10', '11', '12',
      'space',
      '16', '15', '14', '13', 'newline',
      '17', '18', '19', '20',
      'space',
      '25', '24', '23', '22', '21', 'newline',
      '26', '27', '28', '29', '30',
      'space',
      '36', '35', '34', '33', '32', '31', 'newline',
      '37', '38', '39', '40', '41', '42'
    ])
    const middle = ref([
      { carrel: '46', rotate: -45 },
      { carrel: '45', width: 30, height: 40 }, 'newline-40',
      { carrel: '44', width: 30, height: 40 }, 'newline-40',
      { carrel: '43', width: 30, height: 40 }
    ])
    const right = ref([
      '47', '48', '49', '50',
      'space',
      '54', '53', '52', '51', 'newline',
      '55', '56', '57', '58',
      'space',
      '62', '61', '60', '59'
    ])

    const carrels = computed(() => {
      let y = 30
      let x = 30
      const width = 40
      const height = 30
      let space = 20
      const arr = []
      const hash = {}
      for (const carrel of props.takenCarrels) {
        hash[carrel] = false
      }
      for (const carrel of left.value) {
        if (carrel === 'space') {
          y += space + height
          x = 30
        } else if (carrel === 'newline') {
          y += height
          x = 30
        } else {
          arr.push({ carrel, x, y, height, width })
          x += width
        }
      }
      x = 360
      y = 30
      space = 30
      for (const carrel of right.value) {
        if (carrel === 'space') {
          y += space + height
          x = 360
        } else if (carrel === 'newline') {
          y += height
          x = 360
        } else {
          arr.push({ carrel, x, y, height, width })
          x += width
        }
      }
      x = 300
      y = 67
      for (const item of middle.value) {
        if (typeof (item) === 'object') {
          const { carrel, rotate, width: specialWidth, height: specialHeight } = item
          const obj = { carrel, rotate, x, y, height: specialHeight || height, width: specialWidth || width }
          if (rotate) {
            obj.x += 8
            y += 23
          }
          arr.push(obj)
        } else if (item === 'newline-40') {
          y += 40
          x = 300
        }
      }
      return arr
    })

    return {
      left,
      right,
      middle,
      carrels
    }
  }
}
</script>
